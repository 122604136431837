var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "boxed-layout",
      staticStyle: {
        height: "100vh",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
      },
    },
    [
      _c("div", { staticStyle: { "margin-top": "-50px" } }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c("h1", [_vm._v("Fehler!")]),
          _vm._v(" "),
          _setup.errorMessage
            ? _c("div", [
                _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_setup.errorMessage)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.error403
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(
                  "\n        Sie haben nicht die nötigen Berechtigungen um diese Seite anzuzeigen.\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _setup.error404
            ? _c("div", { staticClass: "alert alert-secondary" }, [
                _vm._v("Die Seite existiert nicht."),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: { click: _setup.goBack },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: "arrow-left" } }),
              _vm._v(
                "\n        Zurück zur " +
                  _vm._s(_setup.authenticated ? "Startseite" : "Anmeldung") +
                  "\n      "
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }